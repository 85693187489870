<template>
  <div class="card-grid">
    <Map
      :data="locationData"
      :title="$t('dashboard.summary.respondents_locations')"
      style="grid-column: 1 / -1"
    />
    <ChartCard chartType="pie" questionType="gender" />
    <ChartCard chartType="bar" questionType="commute_distance" />
    <ChartCard chartType="bar" questionType="age" />
    <ChartCard chartType="pie" questionType="co2_emissions" />
    <ChartCard
      chartType="bar-horizontal"
      questionType="transport_mode_distribution"
      full
    />
    <ChartCard
      chartType="pie"
      questionType="commuter_type_distribution"
      legend="right"
      full
    />
    <ChartCard
      chartType="bar-horizontal"
      questionType="different_transportation_method"
      full
    />
    <ChartCard chartType="bar" questionType="offer_sustainable_travelling" />
    <ChartCard chartType="bar" questionType="support_sustainable_travelling" />
  </div>
</template>
<script>
import Map from "./Map.vue";
import ChartCard from "@/components/chart-cards/ChartCard.vue";
export default {
  metaInfo() {
    return {
      title: this.$t("dashboard.nav.summary"),
    };
  },
  components: {
    Map,
    ChartCard,
  },
  name: "inquiry-summary",
  data() {
    return {
      isLoading: true,
      error: "",
      locationData: [],
      genderData: [],
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  methods: {
    getLocations() {
      this.isLoading = false;
      return (this.locationData = []);
    },
  },
  mounted() {
    this.getLocations();
  },
};
</script>
