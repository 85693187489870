<template>
  <div class="map-wrapper">
    <h4 v-if="title">{{ title }}</h4>
    <div class="map">
      <l-map
        v-if="showMap"
        :zoom="zoom"
        tileSize="512"
        :center="center"
        :options="mapOptions"
        style="height: 100%"
        @update:center="centerUpdate"
        @update:zoom="zoomUpdate"
      >
        <l-tile-layer :url="url" :attribution="attribution" />
        <l-circle-marker
          :lat-lng="circle.center"
          :radius="circle.radius"
          :color="circle.color"
        >
          <l-tooltip :options="{ permanent: false, interactive: false }">
            <div @click="innerClick">I am a tooltip</div>
          </l-tooltip>
        </l-circle-marker>
      </l-map>
    </div>
  </div>
</template>
<script>
// import L from "leaflet";
import { latLng } from "leaflet";
import { LMap, LTileLayer, LCircleMarker, LTooltip } from "vue2-leaflet";

export default {
  name: "Map",
  components: {
    LMap,
    LTileLayer,
    LTooltip,
    LCircleMarker,
  },
  data() {
    return {
      zoom: 13,
      zoomOffset: -1,
      maxZoom: 19,
      id: "hsl-map",
      center: latLng(60.192059, 24.945831),
      url: "https://cdn.digitransit.fi/map/v2/hsl-map/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(60.192059, 24.945831),
      withTooltip: latLng(60.192059, 24.945831),
      currentZoom: 11.5,
      currentCenter: latLng(60.192059, 24.945831),
      showParagraph: false,
      circle: {
        center: [60.192059, 24.945831],
        radius: 6,
        color: "#007ac9",
      },
      mapOptions: {
        minZoom: 11.5,
        zoomSnap: 0.5,
      },
      showMap: true,
    };
  },
  methods: {
    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
      alert("Click!");
    },
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    title: String,
  },
};
</script>
<style lang="scss" scoped>
.map-wrapper {
  margin-bottom: 2rem;
  h4 {
    font-weight: 500;
    font-size: 1rem;
  }
  .map {
    width: 100%;
    height: 400px;
    background-color: rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
